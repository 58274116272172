<template>
      <div class="bg-white pb-8 sm:pb-8">
        <div class="mx-auto max-w-2xl py-2">
      <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
    </div>

      <div class="mx-auto mt-8 max-w-7xl px-6 lg:px-8">
        <p class="text-left">Number of students without a Hero ID: {{ studentsWithoutHeroIdCount }}</p>
        <p class="text-left">Fetching students... this process is slow</p>

      
      <!-- Loading Animation -->
      <div v-if="isLoading" class="flex flex-col justify-center items-center h-screen">
        <div class="mb-4 text-xl font-bold">Loading...</div>
        <div class="loader"></div>
      </div>

      <!-- Students without Hero ID Table -->
      <table v-if="!isLoading" class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th class="text-left">Legal Name</th>
            <th class="text-left">MIS ID</th>
            <th class="text-left">Matched Name</th>
            <th class="text-left">Family Code</th>
            <th class="text-left">Hero ID</th>
            <th class="text-left">Contact 1 Email</th>
            <th class="text-left">Sync</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="student in studentsWithoutHeroId" 
            :key="student.id"
            :class="{'bg-yellow-50': student.suggestedFamilyCode}"
          >
            <td class="text-left">{{ student.forename }} {{ student.surname }}</td>
            <td class="text-left">{{ student.mis_id }}</td>
            <td class="text-left">{{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
            <td class="text-left">
              <input 
                v-model="student.familyCode" 
                type="text" 
                class="border rounded px-2 py-1 w-full"
                :placeholder="student.suggestedFamilyCode || generateFamilyCode(student.surname)"
              />
              <div v-if="student.suggestedFamilyCode" class="text-xs text-gray-500 mt-1">
                Suggested from existing family
              </div>
            </td>
            <td class="text-left">{{ student.heroId }}</td>
            <td class="text-left">
              <input 
                v-model="student.contact1Email" 
                type="email" 
                class="border rounded px-2 py-1 w-full"
                :placeholder="student.suggestedContact1Email || 'Enter email (optional)'"
              />
              <div v-if="student.suggestedContact1Email" class="text-xs text-gray-500 mt-1">
                Suggested from existing family
              </div>
            </td>
            <td class="text-left">
              <button 
                @click="syncStudent(student)"  
                class="rounded-md px-3 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                :class="{
                  'bg-indigo-600 hover:bg-indigo-500': !syncingStudents.has(student.mis_id),
                  'bg-gray-400 cursor-wait': syncingStudents.has(student.mis_id)
                }"
                :disabled="syncingStudents.has(student.mis_id)"
              >
                <span v-if="!syncingStudents.has(student.mis_id)">Sync</span>
                <span v-else class="flex items-center">
                  <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Syncing...
                </span>
              </button>
            </td>
          </tr>
          <tr v-if="studentsWithoutHeroId.length === 0">
            <td colspan="7">No students without Hero ID found.</td>
          </tr>
        </tbody>
      </table>

      <!-- Students with Hero ID Table -->
      <p class="text-yellow-500 bg-yellow-50 p-2 mt-8 rounded border border-yellow-200">Newly synced students won't appear until the page is refreshed</p>

      <table v-if="!isLoading" class="min-w-full divide-y divide-gray-300 mt-4">

        <thead>
          <tr>
            <th class="text-left">Legal Name</th>
            <th class="text-left">MIS ID</th>
            <th class="text-left">Matched Name</th>
            <th class="text-left">Family Code</th>
            <th class="text-left">Hero ID</th>
            <th class="text-left">Contact 1 Email</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="student in studentsWithHeroId" :key="student.id">
            <td class="text-left">{{ student.forename }} {{ student.surname }}</td>
            <td class="text-left">{{ student.mis_id }}</td>
            <td class="text-left">{{ student.firstNameLegal }} {{ student.lastNameLegal }}</td>
            <td class="text-left">{{ student.familyCode }}</td>
            <td class="text-left">{{ student.heroId }}</td>
            <td class="text-left">{{ student.contact1Email }}</td>
          </tr>
          <tr v-if="studentsWithHeroId.length === 0">
            <td colspan="7">No students with Hero ID found.</td>
          </tr>
        </tbody>
      </table>


    </div>
    </div>

  </template>
  <script setup>
import axios from 'axios';
import { ref, onMounted, computed } from 'vue'
import wondeService from '../../api/wondeService'

axios.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      endpoint: error.config?.url
    });
    return Promise.reject(error);
  }
);

const apiUrl = import.meta.env?.VITE_API_URL || 
  (process.env.NODE_ENV === 'production' ? 
    'https://pvis.edu.vu' : // Removed /api since it's added in the route
    'http://localhost:3000');

console.log('Current environment:', process.env.NODE_ENV);
console.log('API URL configured as:', apiUrl);

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

const wondeStudents = ref([]);
const localStudents = ref([]);
const students = ref([]);
const schoolId = ref('A1461335658'); // Hardcoded school ID
const isLoading = ref(false); // Loading state
const isDataLoaded = ref(false);
const syncingStudents = ref(new Set()); // Track which students are currently being synced

const generateFamilyCode = (surname) => {
  const year = new Date().getFullYear();
  return `${surname.substring(0, 4).toUpperCase()}${year}`;
};

const syncStudent = async (student) => {
  try {
    // Add student to syncing set
    syncingStudents.value.add(student.mis_id);

    const familyCode = student.familyCode || generateFamilyCode(student.surname);
    
    // Validate required fields
    if (!student.forename || !student.surname || !student.mis_id) {
      console.error('Missing required student data:', {
        forename: student.forename,
        surname: student.surname,
        mis_id: student.mis_id
      });
      alert('Missing required student information. Please ensure name and MIS ID are filled.');
      return;
    }

    const studentData = {
      firstNameLegal: student.forename,
      lastNameLegal: student.surname,
      heroId: student.mis_id,
      familyCode: familyCode,
      currentYear: student.year_group?.name || '',
      status: 'Enrolled',
      // Include additional fields from matching family
      staffChild: student.staffChild || false,
      contact1FirstName: student.contact1FirstName || '',
      contact1LastName: student.contact1LastName || '',
      contact1Email: student.contact1Email || '',
      contact2FirstName: student.contact2FirstName || '',
      contact2LastName: student.contact2LastName || '',
      contact2Email: student.contact2Email || ''
    };

    // Log the exact request being sent
    console.log('Sending request to create student:', {
      url: `${apiUrl}/api/createStudent`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: { studentData },
      environment: process.env.NODE_ENV
    });

    const response = await axios.post('/api/createStudent', 
      { studentData },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        timeout: 10000,
        validateStatus: null
      }
    );

    // Log the complete response
    console.log('Complete server response:', {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      data: response.data,
      error: response.data?.error
    });

    if (response.status === 500) {
      console.error('Server error details:', {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
        error: response.data?.error,
        message: response.data?.error?.message,
        stack: response.data?.error?.stack
      });
      throw new Error(response.data?.error?.message || response.data?.error || 'Internal server error');
    }

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(response.data?.error || `Server returned status ${response.status}`);
    }

    console.log('Student sync successful:', response.data);
    
    // Remove the student locally instead of fetching from server
    students.value = students.value.filter(s => s.mis_id !== student.mis_id);
    
    alert('Student synced successfully!');

  } catch (error) {
    // Enhanced error logging
    const errorDetails = {
      message: error.message,
      response: {
        data: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText,
        headers: error.response?.headers
      },
      request: {
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data ? JSON.parse(error.config.data) : null,
        headers: error.config?.headers,
        baseURL: error.config?.baseURL
      },
      stack: error.stack
    };

    console.error('Detailed sync error:', errorDetails);

    let errorMessage = 'Failed to sync student. ';
    
    if (error.response?.data?.error?.message) {
      errorMessage += error.response.data.error.message;
    } else if (error.response?.data?.error) {
      errorMessage += typeof error.response.data.error === 'string' ? 
        error.response.data.error : 
        JSON.stringify(error.response.data.error, null, 2);
    } else if (error.message) {
      errorMessage += error.message;
    }

    alert(errorMessage);
  } finally {
    // Remove student from syncing set regardless of success/failure
    syncingStudents.value.delete(student.mis_id);
  }
};

const studentsWithoutHeroIdCount = computed(() => {
  return students.value ? students.value.filter(student => !student.heroId || String(student.heroId).trim() === '').length : 0;
});

const studentsWithoutHeroId = computed(() => {
  return students.value ? students.value.filter(student => !student.heroId || String(student.heroId).trim() === '') : [];
});

const studentsWithHeroId = computed(() => {
  return students.value ? students.value.filter(student => student.heroId && String(student.heroId).trim() !== '') : [];
});


// Fetch students from Wonde
const fetchWondeStudents = async () => {
  try {
    const response = await wondeService.getStudents(schoolId.value);
    wondeStudents.value = response.data;
    
    // Log sample student data structure
    if (wondeStudents.value.length > 0) {
      console.log('Sample Wonde student data structure:', {
        firstStudent: wondeStudents.value[0],
        fields: Object.keys(wondeStudents.value[0])
      });
    }
    
    console.log('Fetched students data:', wondeStudents.value);
  } catch (error) {
    console.error('Failed to fetch students from Wonde:', {
      error: error.message,
      details: error.response?.data
    });
    alert('Failed to fetch students from Wonde. Please check the console for details.');
  }
};

// Fetch students from your API endpoint
const fetchStudents = async () => {
  try {
    console.log('Starting API request to /api/getStudents');
    console.log('Current environment:', process.env.NODE_ENV);
    console.log('Current API URL:', apiUrl);
    console.log('Request Headers:', {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Origin': window.location.origin
    });

    const response = await axios.get('/api/getStudents', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      timeout: 30000,
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      }
    });

    if (response.status === 404) {
      console.error('API endpoint not found');
      throw new Error('API endpoint not found. Please check the server configuration.');
    }

    if (!response.data || !Array.isArray(response.data)) {
      console.error('Invalid response data:', response.data);
      throw new Error('Invalid response data from server');
    }

    console.log('API request successful');
    console.log('Response status:', response.status);
    console.log('Response data count:', response.data.length);
    localStudents.value = response.data;
  } catch (error) {
    console.error('=== Detailed Error Information ===');
    console.error('Error details:', {
      message: error.message,
      code: error.code,
      name: error.name,
      stack: error.stack,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        baseURL: error.config?.baseURL,
        timeout: error.config?.timeout,
        headers: error.config?.headers
      },
      response: error.response ? {
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data
      } : 'No response',
      request: error.request ? 'Request made but no response received' : 'Request setup failed'
    });

    if (error.response?.data) {
      const errorMessage = error.response.data.error?.message || error.response.data.error || error.message;
      alert(`Server Error: ${errorMessage}`);
    } else if (error.code === 'ECONNREFUSED' || error.code === 'ERR_NETWORK') {
      console.error('Network connection error:', {
        url: apiUrl,
        error: error.message
      });
      alert(`Cannot connect to server at ${apiUrl}. Please check your network connection and try again.`);
    } else {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please check the console for details.');
    }

    if (!localStudents.value || localStudents.value.length === 0) {
      throw error;
    }
  }
};

onMounted(async () => {
  if (isDataLoaded.value) return;
  
  console.log('Page loaded');
  isLoading.value = true;

  try {
    await fetchWondeStudents();
    await fetchStudents();

    // Ensure we have valid data before proceeding
    if (!Array.isArray(wondeStudents.value) || !Array.isArray(localStudents.value)) {
      console.error('Invalid data structure:', {
        wondeStudents: wondeStudents.value,
        localStudents: localStudents.value
      });
      throw new Error('Invalid data structure received from server');
    }

    // Merge and match students based on names
    students.value = wondeStudents.value.map(wondeStudent => {
      if (!wondeStudent.legal_forename || !wondeStudent.legal_surname) {
        console.warn('Missing name data for student:', wondeStudent);
        return null;
      }

      // Normalize names for comparison
      const wondeStudentName = `${wondeStudent.legal_forename} ${wondeStudent.legal_surname}`.toLowerCase();
      const wondeStudentSurname = wondeStudent.legal_surname.toLowerCase();

      // Find a matching student in localStudents based on normalized names
      const localStudent = localStudents.value.find(local => {
        if (!local || !local.firstNameLegal || !local.lastNameLegal) return false;
        const localStudentName = `${local.firstNameLegal} ${local.lastNameLegal}`.toLowerCase();
        return wondeStudentName === localStudentName;
      });

      // Find any students with the same surname in the local database
      const sameLastNameStudents = localStudents.value.filter(local => {
        if (!local || !local.lastNameLegal) return false;
        return local.lastNameLegal.toLowerCase() === wondeStudentSurname;
      });

      // Get the family code and additional data from existing students with the same last name
      const matchingFamilyStudent = sameLastNameStudents.length > 0 ? sameLastNameStudents[0] : null;
      const suggestedFamilyCode = matchingFamilyStudent?.familyCode || '';

      console.log(`Surname match for ${wondeStudent.legal_surname}:`, {
        matchFound: sameLastNameStudents.length > 0,
        suggestedFamilyCode,
        matchingStudents: sameLastNameStudents.map(s => ({
          name: `${s.firstNameLegal} ${s.lastNameLegal}`,
          familyCode: s.familyCode
        }))
      });

      // Merge data from both sources for the matched student
      return {
        forename: wondeStudent.legal_forename || '',
        surname: wondeStudent.legal_surname || '',
        mis_id: wondeStudent.mis_secondary_id || '',
        year_group: wondeStudent.year_group || {},
        // Use exact match first, then family match, then empty
        familyCode: localStudent?.familyCode || suggestedFamilyCode || '',
        suggestedFamilyCode,
        heroId: localStudent?.heroId || '',
        // Copy additional fields from the matching family record
        staffChild: matchingFamilyStudent?.staffChild || false,
        contact1FirstName: matchingFamilyStudent?.contact1FirstName || '',
        contact1LastName: matchingFamilyStudent?.contact1LastName || '',
        contact1Email: localStudent?.contact1Email || matchingFamilyStudent?.contact1Email || '',
        suggestedContact1Email: matchingFamilyStudent?.contact1Email || '',
        contact2FirstName: matchingFamilyStudent?.contact2FirstName || '',
        contact2LastName: matchingFamilyStudent?.contact2LastName || '',
        contact2Email: matchingFamilyStudent?.contact2Email || '',
        firstNameLegal: localStudent?.firstNameLegal || wondeStudent.legal_forename || '',
        lastNameLegal: localStudent?.lastNameLegal || wondeStudent.legal_surname || ''
      };
    }).filter(Boolean); // Remove any null entries
    
    isDataLoaded.value = true;
  } catch (error) {
    console.error('Error during initial data load:', error);
    alert(error.message || 'Failed to load student data');
  } finally {
    isLoading.value = false;
  }
});
  </script>


<style>
/* Add your loading animation styles here */
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
