<template>
  <div class="inline-flex gap-2">
    <template v-if="!invoice_id">
      <button
        @click="generateInvoice"
        :disabled="isLoading"
        class="text-xs font-semibold rounded-md px-2 py-1 ring-1 ring-inset ring-green-600/20 text-green-600 hover:text-green-500 hover:bg-green-50"
      >
        {{ isLoading ? 'Generating...' : 'Generate' }}
      </button>
    </template>
    <template v-else>
      <a
        :href="`https://go.xero.com/app/!z2WTp/invoicing/edit/${invoice_id}`"
        target="_blank"
        class="text-xs font-semibold rounded-md px-2 py-1 ring-1 ring-inset ring-blue-600/20 text-blue-600 hover:text-blue-500 hover:bg-blue-50"
      >
        View
      </a>
      <button
        v-if="isEditMode"
        @click="voidInvoice"
        :disabled="isLoading"
        class="text-xs font-semibold rounded-md px-2 py-1 ring-1 ring-inset ring-red-600/20 text-red-600 hover:text-red-500 hover:bg-red-50"
      >
        {{ isLoading ? 'Voiding...' : 'Void' }}
      </button>
    </template>
    <div v-if="error" class="text-xs text-red-600">{{ error }}</div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import axios from 'axios';
import xeroService from '../services/xero';

const props = defineProps({
  familyCode: String,
  term: Number,
  students: Array,
  termTotal: Number,
  invoice_id: String,
  isEditMode: Boolean
});

const emit = defineEmits(['invoice-generated', 'invoice-voided']);
const isLoading = ref(false);
const error = ref(null);

const handleXeroError = async (err) => {
  console.error('Xero operation error:', err);
  
  // Check if it's an authentication error
  if (err.response?.status === 401 || err.response?.status === 403) {
    error.value = 'Please reconnect to Xero';
    const { url } = xeroService.getAuthUrl();
    window.location.href = url;
    return;
  }
  
  error.value = err.response?.data?.message || err.message || 'An error occurred';
};

const generateInvoice = async () => {
  isLoading.value = true;
  error.value = null;
  
  try {
    // Check Xero connection first
    const connectionStatus = await xeroService.checkConnection();
    if (!connectionStatus.isConnected) {
      const { url } = xeroService.getAuthUrl();
      window.location.href = url;
      return;
    }

    // Generate invoice data
    const invoiceData = {
      familyCode: props.familyCode,
      term: props.term,
      students: props.students,
      termTotal: props.termTotal
    };
    
    // Create invoice
    const response = await axios.post('/api/generateXeroInvoice', invoiceData);
    
    emit('invoice-generated', {
      term: props.term,
      invoice_id: response.data.invoice_id
    });
    
  } catch (err) {
    await handleXeroError(err);
  } finally {
    isLoading.value = false;
  }
};

const voidInvoice = async () => {
  isLoading.value = true;
  error.value = null;

  try {
    // Check Xero connection first
    const connectionStatus = await xeroService.checkConnection();
    if (!connectionStatus.isConnected) {
      const { url } = xeroService.getAuthUrl();
      window.location.href = url;
      return;
    }

    // Void the invoice in Xero and remove from MongoDB
    await axios.post('/api/voidXeroInvoice', {
      invoice_id: props.invoice_id,
      familyCode: props.familyCode,
      term: props.term,
      students: props.students
    });

    emit('invoice-voided', {
      term: props.term
    });

  } catch (err) {
    await handleXeroError(err);
  } finally {
    isLoading.value = false;
  }
};
</script> 