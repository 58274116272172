<template>
  <div class="w-full py-2">
    <a href="/billing/dashboard" class="text-sm font-semibold leading-6 text-gray-900">Dashboard <span aria-hidden="true">→</span></a>
  </div>
  <div class="relative isolate px-6 pt-14">
    <div class="mb-4 flex justify-between items-center">
      <button @click="updateAllYearLevels" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Update All Year Levels
      </button>
      <div class="flex items-center">
        <label class="inline-flex items-center mr-4">
          <input
            type="checkbox"
            v-model="hideAlumni"
            class="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          >
          <span class="ml-2 text-sm text-gray-700">Hide Alumni Students</span>
        </label>
        <div v-if="message" :class="['px-4 py-2 rounded', message.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700']">
          {{ message.text }}
        </div>
      </div>
    </div>
    <div class="mb-4">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search by name or ID..."
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
      />
    </div>
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900">Student Name</th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">Academic Year</th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">First Year Level Recorded in System (2024)</th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">Current Year</th>
            <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">Actions</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr v-for="student in filteredStudents" 
              :key="student._id"
              :class="{'bg-orange-50': student.firstSystemYearLevel === student.currentYear}">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
              {{ student.firstNameLegal }} {{ student.lastNameLegal }} [{{ student.heroId }}]
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ student.academicYear }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ student.firstSystemYearLevel }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ student.yearLevelStartDate }}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <select 
                v-model="student.currentYear"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              >
                <option v-for="year in yearLevels" :key="year" :value="year">{{ year }}</option>
              </select>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <button 
                @click="saveStudent(student)"
                class="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded text-xs"
              >
                Save
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

const students = ref([]);
const message = ref(null);
const searchQuery = ref('');
const hideAlumni = ref(true); // Default to hiding Alumni students
const yearLevels = [
  'ELP',
  'PRE',
  'FND',
  'Year 1',
  'Year 2',
  'Year 3',
  'Year 4',
  'Year 5',
  'Year 6',
  'Year 7',
  'Year 8',
  'Year 9',
  'Year 10',
  'Year 11',
  'Year 12',
  'Alumni'
];

const filteredStudents = computed(() => {
  let filtered = students.value;
  
  // First filter out Alumni if hideAlumni is true
  if (hideAlumni.value) {
    filtered = filtered.filter(student => student.currentYear !== 'Alumni');
  }
  
  // Then apply search filter if there's a search query
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    filtered = filtered.filter(student => {
      const fullName = `${student.firstNameLegal} ${student.lastNameLegal}`.toLowerCase();
      const heroIdStr = student.heroId?.toString() || '';
      
      return fullName.includes(query) || 
             student.firstNameLegal.toLowerCase().includes(query) || 
             student.lastNameLegal.toLowerCase().includes(query) || 
             heroIdStr.includes(query);
    });
  }
  
  return filtered;
});

const showMessage = (text, type = 'success') => {
  message.value = { text, type };
  setTimeout(() => {
    message.value = null;
  }, 3000);
};

const getNextYearLevel = (currentYear) => {
  const currentIndex = yearLevels.indexOf(currentYear);
  if (currentIndex === -1 || currentIndex === yearLevels.length - 1) return currentYear;
  return yearLevels[currentIndex + 1];
};

const saveStudent = async (student) => {
  const currentYear = 2025; // Hardcoded to 2025 as per requirement
  try {
    const payload = {
      _id: student._id.$oid || student._id,
      currentYear: student.currentYear,
      academicYear: currentYear
    };
    console.log('Attempting to update student with payload:', payload);
    
    await axios.post('/api/updateStudent', payload);
    
    // Update the local academicYear after successful save
    student.academicYear = currentYear;
    showMessage('Student updated successfully');
  } catch (error) {
    console.error('Error updating student. Details:', {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      student: student._id,
      message: error.message
    });
    showMessage(error.response?.data?.error || `Failed to update student: ${error.message}`, 'error');
  }
};

const updateAllYearLevels = async () => {
  const currentYear = 2025; // Hardcoded to 2025 as per requirement
  let updateCount = 0;
  let skippedCount = 0;
  
  try {
    const updatedStudents = students.value.map(student => {
      // Skip students already updated for 2025
      if (student.academicYear === 2025) {
        skippedCount++;
        return student;
      }
      
      // Handle Year 12 students from 2024 - promote to Alumni
      if (student.currentYear === 'Year 12' && student.academicYear === 2024) {
        return {
          ...student,
          currentYear: 'Alumni',
          academicYear: currentYear
        };
      }
      
      // Special handling for Year 11 students - promote to Year 12
      if (student.currentYear === 'Year 11') {
        return {
          ...student,
          currentYear: 'Year 12',
          academicYear: currentYear
        };
      }
      
      // For other students, check if they need to progress
      if (student.currentYear === student.firstSystemYearLevel) {
        const yearsSinceStart = currentYear - student.yearLevelStartDate;
        if (yearsSinceStart === 1) {
          const nextYear = getNextYearLevel(student.currentYear);
          return { 
            ...student, 
            currentYear: nextYear,
            academicYear: currentYear 
          };
        }
      }
      return student;
    });

    // Update all modified students
    for (const student of updatedStudents) {
      if (student.currentYear !== students.value.find(s => s._id === student._id).currentYear) {
        await saveStudent(student);
        updateCount++;
      }
    }
    
    showMessage(`Successfully updated ${updateCount} students (${skippedCount} students skipped - already at 2025)`);
    // Refresh the list
    await fetchStudents();
  } catch (error) {
    showMessage('Error updating students', 'error');
  }
};

const fetchStudents = async () => {
  try {
    const response = await axios.get('/api/getStudents');
    students.value = response.data;
  } catch (error) {
    console.error('Error fetching students:', error);
    showMessage('Error fetching students', 'error');
  }
};

onMounted(fetchStudents);
</script>

<style scoped>
.overflow-x-auto {
  @apply -mx-4 mt-4 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg;
}

/* Add disabled styles */
select:disabled, button:disabled {
  @apply opacity-50 cursor-not-allowed;
}
</style>