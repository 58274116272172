<template>
  <div class="xero-connect">
    <div v-if="loading" class="loading">
      <span>Checking Xero connection...</span>
    </div>
    <div v-else>
      <div v-if="isConnected" class="connected">
        <h3>Connected to Xero</h3>
        <button @click="handleDisconnect" class="btn btn-primary bg-red-100 text-red-600 ring-red-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium ring-1 ring-inset">
          Disconnect from Xero
        </button>
      </div>
      <div v-else class="not-connected">
        <h3>Not Connected to Xero</h3>
        <button @click="handleConnect" class="btn btn-primary bg-blue-100 text-blue-600 ring-blue-600 inline-flex items-center rounded-md px-3 py-2 text-sm font-medium ring-1 ring-inset">
          Connect to Xero
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import xeroService from '../services/xero';

export default {
  name: 'XeroConnect',
  
  data() {
    return {
      loading: true,
      isConnected: false,
      error: null
    };
  },

  async created() {
    await this.checkConnection();
  },

  methods: {
    async checkConnection() {
      try {
        this.loading = true;
        const status = await xeroService.checkConnection();
        this.isConnected = status.isConnected;
      } catch (error) {
        console.error('Error checking Xero connection:', error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    async handleConnect() {
      try {
        const { url, state } = xeroService.getAuthUrl();
        // Store state in localStorage for verification
        localStorage.setItem('xero_auth_state', state);
        window.location.href = url;
      } catch (error) {
        console.error('Error connecting to Xero:', error);
        this.error = error.message;
      }
    },

    async handleDisconnect() {
      try {
        await xeroService.disconnect();
        this.isConnected = false;
      } catch (error) {
        console.error('Error disconnecting from Xero:', error);
        this.error = error.message;
      }
    }
  }
};
</script>

<style scoped>
.xero-connect {
}

.loading {
  text-align: center;
  padding: 20px;
}

.connected, .not-connected {
  text-align: center;
}

button:hover {
  opacity: 0.9;
}
</style> 