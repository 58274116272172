<template>
  <div class="relative isolate px-6 pt-14 lg:px-8">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>

      <div class="mx-auto max-w-2xl py-16 sm:py-24 lg:py-28">
        <div class="mb-12">
          <XeroConnect />
        </div>

        <div class="text-center">
          <h1 class="text-6xl font-bold text-left  tracking-tight text-gray-900 ">PVIS Billing Dashboard </h1>
          <h2 class="text-4xl font-bold text-left  tracking-tight text-gray-900 py-2">for school year {{ new Date().getFullYear() }}</h2>

          <p class="text-normal font-bold text-left tracking-tight text-gray-900 flex items-center">
            <a href="/billing/wonde-sync" class="hover:text-indigo-600">Sync Students [{{ studentCount }} Students]</a>
            <span v-if="isLoadingStudents" class="ml-2">
              <div class="spinner"></div>
            </span>
          </p> 
          <p class="text-normal font-bold text-left tracking-tight text-gray-900 flex items-center">
            <a href="/billing/yearly" class="hover:text-indigo-600">Generate Invoices for Families [{{ familyCount }} Families]</a>
            <span v-if="isLoadingFamilies" class="ml-2">
              <div class="spinner"></div>
            </span>
          </p> 
          <p class="text-normal font-bold text-left tracking-tight text-gray-400 flex items-center">
            <a href="/billing/contact" class="hover:text-indigo-600">Edit Contact (Broken)</a>
          </p> 
          <p class="text-normal font-bold text-left tracking-tight text-gray-400 flex items-center">
            <a href="/billing/settings" class="hover:text-indigo-600">Settings (Broken)</a>
          </p> 
          <p class="text-normal font-bold text-left tracking-tight text-gray-900 flex items-center">
            <a href="#" @click.prevent="logout" class="text-sm font-semibold leading-6 text-gray-900">Log Out <span aria-hidden="true">→</span></a>
          </p> 

      </div>
      </div>
      <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
        <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>


      

    <p class="mt-6 text-lg leading-8 text-gray-600">Please contact <a href="mailto:pierre-luc@ajc-vanuatu.com" class="text-indigo-600 hover:text-indigo-500">pierre-luc@ajc-vanuatu.com</a> for any errors or suggestions</p>

  </div>

  
</template>

<script setup>
import { onUnmounted, onMounted, ref } from 'vue';
import { auth } from '../firebase'
import { useRouter } from 'vue-router'
import axios from 'axios';
import XeroConnect from '../components/XeroConnect.vue';
import XeroService from '../services/xero';

const students = ref([]);
const router = useRouter()
const isMounted = ref(true)
const studentCount = ref(0);
const familyCount = ref(0); // Add this line for storing family count
const isLoadingStudents = ref(false);
const isLoadingFamilies = ref(false);


onMounted(async () => {
  try {
    // Check Xero connection status
    await XeroService.checkConnection();

    isLoadingStudents.value = true;
    // Fetch student count
    const countResponse = await axios.get('/api/getStudents?count=true');
    studentCount.value = countResponse.data.count;
    isLoadingStudents.value = false;
    
    isLoadingFamilies.value = true;
    // Fetch family count
    const familyResponse = await axios.get('/api/getStudents?familyCount=true');
    familyCount.value = familyResponse.data.count;
    isLoadingFamilies.value = false;
    
    // Fetch full student data
    const response = await axios.get('/api/getStudents');
    students.value = response.data;
    console.log('Received students data successfully');
  } catch (error) {
    console.error('Error fetching students:', error);
    isLoadingStudents.value = false;
    isLoadingFamilies.value = false;
  }
});

onUnmounted(() => {
  isMounted.value = false
})

const logout = async () => {
  await auth.signOut()
  if (isMounted.value) {
    router.push('/billing/login') // replace with your login route
  }
}


</script>

<style>
.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #4f46e5;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>