const axios = require('axios');

class XeroService {
  constructor() {
    this.baseURL = process.env.NODE_ENV === 'development' ? 
      'http://localhost:3000' : 
      'https://pvis.edu.vu';
    this.redirectUri = `${this.baseURL}/api/auth`;
    this.scopes = ['openid', 'profile', 'email', 'accounting.settings', 'accounting.transactions', 'accounting.contacts', 'accounting.attachments', 'offline_access'];
    this.clientId = process.env.XERO_CLIENT_ID || process.env.VUE_APP_XERO_CLIENTID;
    this.clientSecret = process.env.XERO_CLIENT_SECRET || process.env.VUE_APP_XERO_SECRET;
  }

  async checkConnection() {
    try {
      const response = await axios.get(`${this.baseURL}/api/checkConnection`);
      return response.data;
    } catch (error) {
      console.error('Error checking Xero connection:', error);
      return {
        isConnected: false,
        isExpired: true,
        hasAccessToken: false,
        hasTenantId: false
      };
    }
  }

  getAuthUrl() {
    const state = Math.random().toString(36).substring(7);
    const url = `https://login.xero.com/identity/connect/authorize?` +
      `response_type=code` +
      `&client_id=${this.clientId}` +
      `&redirect_uri=${encodeURIComponent(this.redirectUri)}` +
      `&scope=${encodeURIComponent(this.scopes.join(' '))}` +
      `&state=${state}`;
    
    return { url, state };
  }

  async handleCallback(code) {
    try {
      console.log('Handling auth callback with code:', code);
      
      if (!this.clientId || !this.clientSecret) {
        throw new Error('Client credentials not configured');
      }

      // Create form data
      const formData = new URLSearchParams();
      formData.append('grant_type', 'authorization_code');
      formData.append('code', code);
      formData.append('redirect_uri', this.redirectUri);

      // Basic auth with client credentials
      const auth = Buffer.from(`${this.clientId}:${this.clientSecret}`).toString('base64');
      
      console.log('Using client ID:', this.clientId);
      console.log('Redirect URI:', this.redirectUri);
      
      // Exchange code for tokens
      const tokenResponse = await axios.post('https://identity.xero.com/connect/token', 
        formData,
        {
          headers: {
            'Authorization': `Basic ${auth}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      console.log('Token response:', tokenResponse.data);
      const { access_token, refresh_token } = tokenResponse.data;

      // Get tenant ID
      const tenantResponse = await axios.get('https://api.xero.com/connections', {
        headers: {
          'Authorization': `Bearer ${access_token}`,
          'Content-Type': 'application/json'
        }
      });

      const tenant_id = tenantResponse.data[0]?.tenantId;

      // Save auth details
      await axios.post(`${this.baseURL}/api/authstorage`, {
        access_token,
        tenant_id,
        refresh_token
      });

      return {
        success: true,
        access_token,
        tenant_id,
        refresh_token
      };
    } catch (error) {
      console.error('Error in auth callback:', error);
      throw error;
    }
  }

  async refreshToken() {
    try {
      const response = await axios.post(`${this.baseURL}/api/refreshToken`);
      return response.data;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  }

  async disconnect() {
    try {
      const response = await axios.post(`${this.baseURL}/api/disconnectXero`);
      return response.data;
    } catch (error) {
      console.error('Error disconnecting from Xero:', error);
      throw error;
    }
  }
}

module.exports = new XeroService(); 