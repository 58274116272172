import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from './layouts/MainLayout.vue'
import BillingLayout from './layouts/BillingLayout.vue'
import { auth } from './firebase.js'
import HomePage from './views/HomePage.vue'
import OurTeam from './views/OurTeam.vue'
import OurHistory from './views/OurHistory.vue'
import OurSchool from './views/OurSchool.vue'
import OurValues from './views/OurValues.vue'
import GeneralInformation from './views/GeneralInformation.vue'
import EnrolmentProcess from './views/EnrolmentProcess.vue'
import Contact from './views/ContactPage.vue'
import TermDates from './views/TermDates.vue'
import CurriculumPage from './views/CurriculumPage.vue'
import AsaPage from './views/AsaPage.vue'
import DisasterPreparedness from './views/DisasterPreparedness.vue'
import StrategicPlan from './views/StrategicPlan.vue'
import SchoolFees from './views/SchoolFees.vue'
import YumiStrong from './views/YumiStrong.vue'
import OurBoard from './views/OurBoard.vue'
import Employment from './views/EmploymentPage.vue'
import SacePage from './views/SacePage.vue'
import WondeSync from './views/WondeSync.vue'
import BillingLogin from './views/LoginPage.vue'
import BillingDashboard from './views/BillingDashboard.vue'
import BillingSettings from './views/BillingSettings.vue'
import BillingContact from './views/BillingContact.vue'
import SchoolYearbook from './views/SchoolYearbook.vue'
import BillingYearly from './views/BillingYearly.vue'
import BillingUpdateYearLevel from './views/BillingUpdateYearLevel.vue'

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
    path: '/',
    name: 'Homepage',
    component: HomePage
  },
  {
    path: '/our-team',
    name: 'OurTeam',
    component: OurTeam
  },
  {
    path: '/our-board',
    name: 'OurBoard',
    component: OurBoard
  },
  {
    path: '/our-history',
    name: 'OurHistory',
    component: OurHistory
  },
  {
    path: '/our-school',
    name: 'OurSchool',
    component: OurSchool
  },
  {
    path: '/our-values',
    name: 'OurValues',
    component: OurValues
  },
  {
    path: '/term-dates',
    name: 'TermDates',
    component: TermDates
  },
  {
    path: '/our-curriculum',
    name: 'CurriculumPage',
    component: CurriculumPage
  },
  {
    path: '/asa',
    name: 'After School Activities',
    component: AsaPage
  },
  {
    path: '/disaster-preparedness',
    name: 'Disaster Preparedness',
    component: DisasterPreparedness
  },
  {
    path: '/strategic-plan',
    name: 'Strategic Plan',
    component: StrategicPlan
  },
  {
    path: '/yearbook',
    name: 'PVIS Yearbook',
    component:SchoolYearbook
  },
  {
    path: '/school-fees',
    name: 'School Fees',
    component: SchoolFees
  },
  {
    path: '/yumi-strong',
    name: 'Yumi Strong',
    component: YumiStrong
  },
  {
    path: '/general-information',
    name: 'GeneralInformation',
    component: GeneralInformation
  },
  {
    path: '/enrolment-process',
    name: 'EnrolmentProcess',
    component: EnrolmentProcess
  },
  {
    path: '/sace',
    name: 'SacePage',
    component: SacePage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/employment',
    name: 'Employment',
    component: Employment
  },
]
},
{
  path: '/billing',
  component: BillingLayout,
  children: [
    {
      path: '', // This matches /billing directly
      redirect: '/billing/login' // This redirects to /billing/login
    },
    {
      path: 'dashboard',
      component: BillingDashboard,
      meta: { requiresAuth: true } // Add this line
    },
    {
      path: 'yearly',
      component: BillingYearly,
      meta: { requiresAuth: true }
    },
    {
      path: 'contact',
      component: BillingContact,
      meta: { requiresAuth: true }
    },
    {
      path: 'settings',
      component: BillingSettings,
      meta: { requiresAuth: true }
    },
    {
      path: 'updateyearlevel',
      component: BillingUpdateYearLevel,
      meta: { requiresAuth: true }
    },
    {
      path: 'login',
      component: BillingLogin
    },
    {
      path: 'wonde-sync',
      name: 'WondeSync',
      component: WondeSync,
      meta: { requiresAuth: true }
    },
]
}
]

let router;

auth.onAuthStateChanged(user => {
  if (!router) {
    router = createRouter({
      history: createWebHistory(process.env.BASE_URL),
      routes
    });

    router.beforeEach((to, from, next) => {
      const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
      if (requiresAuth && !user) {
        next('/billing/login');
      } else {
        next();
      }
    });
  }
});

export default () => {
  return new Promise(resolve => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      if (router) {
        resolve(router);
      }
      unsubscribe();
    });
  });
};